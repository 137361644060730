import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

import './css/library/bootstrap.min.css';
import './css/body.css';
import './css/debug.css';
import './css/element.css';
import './css/footer.css';
import './css/form.css';
import './css/navbar.css';
import './css/panel.css';
import './css/slideshow.css';
import './css/status.css';

import { PageDisclaimer, PageHomepage, PagePrivacy } from './components/Page';

import { SectionFooter, SectionNavigation } from './components/Section';

import { ElementDebugResponsive } from './components/Element';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function App()
{
    // Debug
    const debug = useRef(false);
    
    // IP address
    const ip_address = useRef('https://thissen.ai');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        ip_address.current = (window.location.origin).replace('3000', '8001');
        console.log('IP: '+ip_address.current);

        debug.current = true;
    }

    // Translation
    const [translation_language, translation_set_language] = useState(null);
    const [translation_data, translation_set_data] = useState(null);

    function Translation(props)
    {
        const {language} = useParams();
        const navigate = useNavigate();
       
        let translation_language = props.translation_language;
        useEffect(() => {
            if (translation_language === null || language !== translation_language)
            {
                if (language === 'nl-NL' || language === 'en-US')
                {
                    // Set so it won't fire the event multiple times
                    translation_set_language(language);

                    const get = async() => {
                        try
                        {
                            let params = {
                                language: language
                            };
    
                            const response = await axios.get(ip_address.current+'/backend/main/api/translation', { params });
                            
                            // Build dictionary
                            let dict = {}
                            for (let i = 0; i < response.data.length; i++)
                            {
                                if (response.data[i][1] !== '')
                                    dict[response.data[i][0]] = response.data[i][1];
                            }
                            
                            translation_set_data(dict);
    
                            if (debug.current === true)
                                console.log(response.data);
                        }
    
                        catch (error)
                        {
                            console.log(error);
                        }
                    }
    
                    get();
                }
                else
                    navigate('/nl-NL');
            }
            
        }, [language, translation_language, navigate]);

        return null;
    }

    // Metatag
    const [metatag_data, metatag_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/main/api/metatag');
                // <str> url
                // <str> metatag_title
                // <str> metatag_description
                
                metatag_set_data(response.data);
                
                if (debug.current === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, []);

    const [metatag_title, metatag_set_title] = useState('');
    const [metatag_description, metatag_set_description] = useState('');

    function Metatag()
    {
        const location = useLocation();
        
        useEffect(() => {
            if (metatag_data !== null)
            {
                for (let i = 0; i < metatag_data.length; i++)
                {
                    let match = matchPath({path:metatag_data[i][0], strict:true}, location['pathname']);
                    if (match !== null)
                    {
                        metatag_set_title(metatag_data[i][1]);
                        metatag_set_description(metatag_data[i][2]);

                        if (debug.current === true)
                        {
                            console.log('Meta title: '+metatag_data[i][1]);
                            console.log('Meta description: '+metatag_data[i][2]);
                        }

                        break;
                    }
                }
            }
        }, [location]);
        
        return null;
    }

    // Routes
    const routes = [];
    const routes_urls = {
        'nl-NL': {},
        'en-US': {}
    };

    function routes_add(url_key, url_dict, page)
    {
        let key = 0;
        for (let language in url_dict)
        {
            routes.push(
                <Route key={key} exact path={url_dict[language]} element={
                    <React.Fragment>
                        <Translation
                            translation_language={translation_language} />
                        <Metatag />

                        {page}
                    </React.Fragment>
                } />
            );
            
            let url_split = url_dict[language].split('/', 1)[0];
            routes_urls[language][url_key] = url_split;
            
            key++;
        }
    }

    routes_add('home', {
        'nl-NL': '',
        'en-US': '',
    }, (
        <PageHomepage
            debug={debug.current}
            routes_urls={routes_urls}
            ip_address={ip_address.current}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('disclaimer', {
        'nl-NL': 'vrijwaring',
        'en-US': 'disclaimer',
    }, (
        <PageDisclaimer
            debug={debug.current}
            ip_address={ip_address.current}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));

    routes_add('privacy', {
        'nl-NL': 'privacyverklaring',
        'en-US': 'privacy-statement',
    }, (
        <PagePrivacy
            debug={debug.current}
            ip_address={ip_address.current}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));

    // Render
    return (
        <HelmetProvider>
            <Helmet>
                <title>{metatag_title}</title>
                <meta name="description" content={metatag_description} />
            </Helmet>
            <Router>
                <SectionNavigation
                    debug={debug.current}
                    routes_urls={routes_urls}
                    translation_data={translation_data}
                    translation_language={translation_language} />
                
                <Routes>
                    { /* Redirect to default language */ }
                    <Route exact path="" element={
                        <Navigate to="/nl-NL" />
                    } />

                    <Route exact path=":language">
                        {routes}
                    </Route>
                    
                    { /* Redirect on not found */ }
                    <Route path="*" element={
                        <Navigate to="/" />
                    } />
                </Routes>

                <SectionFooter
                    debug={debug.current}
                    routes_urls={routes_urls}
                    translation_data={translation_data}
                    translation_language={translation_language} />

                { debug.current === true &&
                    <ElementDebugResponsive
                        ip_address={ip_address.current} />
                }
            </Router>
        </HelmetProvider>
    )
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);